import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  color: black;
  text-align: center;
  width: 100%;
  padding: 10px 0;
  flex-shrink: 0;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0 10px;  // Adds 10px margin to each side
`;

const ExternalLink = styled.a`
  color: black;
  text-decoration: none;
  margin: 0 10px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      Ayurdata AI and AyurEHR are services of Elysium Arcanum Inc. &copy; 2024 Elysium Arcanum Inc. All Rights Reserved. |
      <ExternalLink href="http://www.ayurdata.in">Ayurdata India</ExternalLink> |
      <StyledLink to="/terms">Terms of Service</StyledLink> |
      <StyledLink to="/privacy">Privacy Policy</StyledLink>
    </FooterContainer>
  );
};

export default Footer;