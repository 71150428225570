import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Navbar from '../components/Navbar';
import FAQ from '../components/FAQ';
import AboutUsSection from './AboutUsSection';
import ContactForm from './ContactForm';
import SectionContainer from '../styles/SectionContainer';


const PageContainer = styled.div`
  overflow-y: auto;
  min-height: 100vh;
  scroll-behavior: smooth;
`;

const SlideAnimation = keyframes`
  0%, 100% {
    transform: translateY(100%);
    opacity: 0;
  }
  5%, 30% {
    transform: translateY(0%);
    opacity: 1;
  }
  35%, 95% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const Header = styled.h1`
  font-size: 2em;
  font-weight: 600;
  color: #1B1212;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

    @media (max-width: 768px) {
    justify-content: center; // Center the header content
    flex-direction: column; // Stack the elements vertically
    text-align: center; // Ensure text is centered on smaller screens
  }
`;

const Slider = styled.span`
  position: relative;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  margin: 0 10px;
`;

const SliderWord = styled.span`
  display: block;
  position: absolute;
  height: 100%;
  line-height: 1em;
  animation: ${SlideAnimation} 3s linear infinite;
  color: #D3D3D3;
  white-space: nowrap;


  &:nth-child(2) {
    animation-delay: 1s;
  }
  &:nth-child(3) {
    animation-delay: 2s;
  }
`;

const HomePage = () => {
  const [currentWidth, setCurrentWidth] = useState(0);
  const wordRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);
  const [lockScroll, setLockScroll] = useState(false);

  useEffect(() => {
    const localRefs = wordRefs.current;
    const updateWidth = () => {
      const widths = localRefs.map(ref => ref.current ? ref.current.offsetWidth : 0);
      const maxWidth = Math.max(...widths);
      setCurrentWidth(maxWidth);
    };

    localRefs.forEach(ref => {
      ref.current.addEventListener('animationiteration', updateWidth);
    });

    return () => {
      localRefs.forEach(ref => {
        if (ref.current) {
          ref.current.removeEventListener('animationiteration', updateWidth);
        }
      });
    };
  }, []);


  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
      const destination = window.pageYOffset + event.deltaY; // Modify this for speed control
      window.scrollTo({
        top: destination,
        behavior: 'smooth' // This controls the smooth scroll
      });
    };
  
    window.addEventListener('wheel', handleScroll, { passive: false });
  
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <PageContainer>
        <SectionContainer>
          <Header>
            A New 
            <Slider style={{ width: `${currentWidth}px` }}>
              <SliderWord ref={wordRefs.current[0]}>EHR</SliderWord>
              <SliderWord ref={wordRefs.current[1]}>AI</SliderWord>
              <SliderWord ref={wordRefs.current[2]}>Tool</SliderWord>
            </Slider>
            for the Modern World
          </Header>
        </SectionContainer>
        <SectionContainer id="about-us">
          <AboutUsSection />
        </SectionContainer>
        <SectionContainer id="contact">
          <ContactForm />
        </SectionContainer>
        <SectionContainer id="faq">
          <FAQ />
        </SectionContainer>
      </PageContainer>
    </>
  );
};

export default HomePage;
