import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
`;

const Header = styled.h1`
  color: #333;
`;

const Section = styled.section`
  margin-top: 20px;
`;

const SubHeader = styled.h2`
  color: #666;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const List = styled.ul`
  list-style: inside square;
`;

const ListItem = styled.li``;

const Bold = styled.span`
  font-weight: bold;
`;

function PrivacyPage() {
  return (
    <Container>
      <Header>Privacy Policy</Header>
      <Paragraph>Last updated: June 11th, 2024</Paragraph>
      <Paragraph>This Privacy Policy details the practices of Elysium Arcanum Inc. ("Company," "we," "us," or "our") regarding the collection, use, processing, and disclosure of personal data when you utilize our Services. This policy applies to our website at www.ayurdata.ai, any associated mobile applications, and all related services linked to this policy.</Paragraph>
      <Paragraph>For questions or concerns about our privacy practices, please contact us at privacy@ayurdata.ai.</Paragraph>

      <Section>
        <SubHeader>SUMMARY OF KEY POINTS</SubHeader>
        <Paragraph>This summary provides an overview of our Privacy Policy. For comprehensive details, please refer to the full text below.</Paragraph>
      </Section>

      <Section>
        <SubHeader>1. WHAT INFORMATION DO WE COLLECT?</SubHeader>
        <Paragraph>In Short: We collect personal information that you provide directly to us or that we gather through your use of our Services.</Paragraph>
        <Paragraph>Detailed Information: We collect information that is essential for providing our Services globally. This includes personal details such as your name, email address, and contact information, which you provide when you register an account, participate in our events, or communicate with us. We also gather technical data about your interactions with our website, utilizing cookies and similar technologies. This global collection practice ensures our service is responsive and tailored to the needs of users worldwide.</Paragraph>
      </Section>

      <Section>
        <SubHeader>2. HOW DO WE PROCESS YOUR INFORMATION?</SubHeader>
        <Paragraph>We process your information to deliver and enhance our Services, communicate with you, ensure security, prevent fraud, and fulfill legal obligations. We may also process your information based on your consent for specified purposes.</Paragraph>
      </Section>

      <Section>
        <SubHeader>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</SubHeader>
        <Paragraph>We share your information with third parties only in specific situations as outlined in this policy and in compliance with applicable laws.</Paragraph>
      </Section>

      <Section>
        <SubHeader>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</SubHeader>
        <Paragraph>Yes, we utilize cookies and other tracking technologies to collect and store information about your interactions with our Services.</Paragraph>
      </Section>

      <Section>
        <SubHeader>5. HOW DO WE HANDLE THIRD-PARTY LOGINS?</SubHeader>
        <Paragraph>If you choose to register or log in to our services using a third-party service, we access information about you from that service, as permitted by your settings on the third-party service and its privacy policy.</Paragraph>
      </Section>
      
      <Section>
        <SubHeader>6. HOW LONG DO WE KEEP YOUR INFORMATION?</SubHeader>
        <Paragraph>In Short: We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy unless a longer retention period is legally required or permissible.</Paragraph>
        <Paragraph>Detailed: We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, complying with our global legal obligations for data retention. The retention period may vary by region depending on specific legal, tax, or operational requirements, ensuring that your data is managed appropriately worldwide.</Paragraph>
      </Section>

      <Section>
        <SubHeader>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</SubHeader>
        <Paragraph>We implement appropriate technical and organizational security measures to protect your personal information.</Paragraph>
        <Paragraph>We are committed to protecting your personal data from unauthorized access, alteration, disclosure, or destruction. We have implemented various policies including data encryption, secure data storage facilities, and regular audits of our practices and procedures.</Paragraph>
      </Section>

      <Section>
        <SubHeader>8. DO WE COLLECT INFORMATION FROM MINORS?</SubHeader>
        <Paragraph>We do not knowingly collect data from individuals under 18 years of age without parental consent.</Paragraph>
        <Paragraph>Our Services are not directed to children under 18. If we learn that we have collected personal information from a child under 18 without parental consent, we will take steps to delete the information as soon as possible.</Paragraph>
      </Section>

      <Section>
        <SubHeader>9. WHAT ARE YOUR PRIVACY RIGHTS?</SubHeader>
        <Paragraph>Depending on your location, you may have rights regarding access to and control over your personal data.</Paragraph>
        <Paragraph>You have the right to access, correct, or delete your personal information, or to restrict its processing. You also have the right to object to processing and, where we have asked for your consent to process your data, to withdraw this consent. These rights can be exercised by contacting us directly.</Paragraph>
      </Section>

      <Section>
        <SubHeader>10. CONTROLS FOR DO-NOT-TRACK FEATURES</SubHeader>
        <Paragraph>Most web browsers and some mobile operating systems include a Do-Not-Track feature that you can activate to signal your privacy preferences. However, no uniform technology standard for recognizing and implementing DNT signals has been finalized.</Paragraph>
      </Section>

      <Section>
        <SubHeader>11. DO WE MAKE UPDATES TO THIS NOTICE?</SubHeader>
        <Paragraph>Yes, we will update this notice as needed to stay compliant with relevant laws and to reflect changes to our practices.</Paragraph>
      </Section>

      <Section>
        <SubHeader>12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</SubHeader>
        <Paragraph>Based on the laws of your country, you may have the right to request access to, correct, or delete your personal information. To manage your personal information, please contact us at privacy@ayurdata.ai.</Paragraph>
        <Paragraph>We are committed to ensuring that you have full control over your personal data. In accordance with global data protection laws, you are entitled to a broad spectrum of rights regarding the collection and use of your personal information. These rights include:</Paragraph>
        <List>
          <ListItem><Bold>Right to Access:</Bold> You have the right to request a copy of the personal data we hold about you. This allows you to know exactly what information we have collected and how it is being used.</ListItem>
          <ListItem><Bold>Right to Rectification:</Bold> If any data we hold is inaccurate or incomplete, you have the right to have it corrected. You can update your information at any time to ensure all decisions made based on this data are well informed.</ListItem>
          <ListItem><Bold>Right to Erasure (‘Right to be Forgotten’):</Bold> You can request the deletion or removal of your personal data when there is no compelling reason for its continued processing. This right is particularly relevant where the data is no longer necessary for the purposes for which it was originally collected or processed.</ListItem>
          <ListItem><Bold>Right to Restrict Processing:</Bold> Under certain conditions, you have the right to 'block' or suppress further use of your data. When processing is restricted, we are permitted to store your data but not to use it further.</ListItem>
          <ListItem><Bold>Right to Data Portability:</Bold> This right allows you to obtain and reuse your personal data for your own purposes across different services. It allows you to move, copy, or transfer personal data easily from one IT environment to another in a safe and secure way, without hindrance to usability.</ListItem>
          <ListItem><Bold>Right to Object:</Bold> You are entitled to object to the processing of your personal data based on grounds relating to your particular situation. This includes the right to object to processing for direct marketing, which can be exercised through unsubscribe mechanisms.</ListItem>
          <ListItem><Bold>Rights Related to Automated Decision Making Including Profiling:</Bold> You have the right not to be subject to a decision when it is based on automated processing and it produces a legal effect or similarly significantly affects you.</ListItem>
        </List>
        <Paragraph>To exercise any of these rights, please contact us directly at privacy@ayurdata.ai. You may also use this contact to express any concerns or queries regarding our data practices. In your request, please specify the action you wish us to take and we will respond within the legal time frame. We may need to verify your identity as a security measure to protect your privacy and ensure your data is not disclosed to any person who has no right to receive it.</Paragraph>
      </Section>

      <Section>
        <SubHeader>13. DATA UTILIZATION FOR HEALTH MODELS</SubHeader>
        <Paragraph>In Short: We leverage the data collected to improve and train our health-related artificial intelligence (AI) models.</Paragraph>
        <Paragraph>Detailed Information: We utilize personal and non-personal data collected through our Services to develop, train, and refine our AI-driven health models, which are integral to advancing the functionality and accuracy of our offerings. These models are designed to enhance predictive healthcare analytics and support decision-making processes. All data used for such purposes are processed in compliance with this Privacy Policy and in accordance with applicable data protection laws. Data anonymization and pseudonymization techniques are employed to ensure the privacy and security of the data used in these models.</Paragraph>
      </Section>

      <Section>
        <SubHeader>14. INTERNATIONAL DATA TRANSFERS</SubHeader>
        <Paragraph>In Short: We may transfer, store, and process your information in countries other than your own.</Paragraph>
        <Paragraph>Detailed Information: Our operations are global, and we may transfer, store, and process your information in jurisdictions outside of your country of residence—including in the United States, Europe, Asia, and Australia. Where data protection laws may vary, we implement standard contractual clauses recognized globally and adhere to applicable legal frameworks such as the EU-US Privacy Shield Framework, ensuring your information is protected with the highest standards of security and compliance.</Paragraph>
      </Section>

      <Section>
        <SubHeader>15. COMPREHENSIVE DATA COLLECTION AND USAGE PRACTICES</SubHeader>
        <Paragraph>In Short: We engage in extensive data collection practices to comprehensively serve our users’ needs, ensuring high standards of data privacy and security.</Paragraph>
        <Paragraph>Detailed Information: Our data collection is comprehensive and designed to respect global data protection standards. We gather a variety of information types, including demographic data, health metrics, and usage statistics, following transparent collection methods. This data supports the personalization of our Services and helps maintain high security and compliance standards globally. We are committed to processing this data solely for the purposes outlined in our Privacy Policy and in compliance with international regulatory requirements.</Paragraph>
      </Section>

      <Section>
        <SubHeader>16. EXTENDED USER RIGHTS ON DATA</SubHeader>
        <Paragraph>In Short: We recognize and facilitate the extended rights of users concerning their personal data under global data protection laws.</Paragraph>
        <Paragraph>Detailed Information: Globally, users have extensive rights to access, rectify, delete, or restrict the use of their personal information. Additionally, users have the right to object to certain processing activities and to obtain a copy of their personal data in a machine-readable format. We provide mechanisms for users to exercise these rights easily and transparently. Detailed instructions for exercising these rights can be found under the Section 'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?'</Paragraph>
      </Section>

      <Section>
        <SubHeader>17. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</SubHeader>
        <Paragraph>If you have questions or comments about this notice, you may email our Data Protection Officer, Austin Mann, at Austin@ayurdata.ai, call us at (+1) 341-207-2186, or send a letter to:</Paragraph>
        <Paragraph>Elysium Arcanum Inc.<br/>2747 Haste St APT 12<br/>Berkeley, California, United States of America, 94704</Paragraph>
      </Section>

    </Container>
  );
}

export default PrivacyPage;