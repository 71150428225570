import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
`;

const Header = styled.h1`
  color: #333;
`;

const SubHeader = styled.h2`
  color: #666;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const List = styled.ul`
  list-style: inside square;
`;

const ListItem = styled.li``;

const Bold = styled.span`
  font-weight: bold;
`;

function TermsPage() {
  return (
    <Container>
      <Header>Terms of Service</Header>
      <Paragraph>Last updated: June 11th, 2024</Paragraph>
      <Paragraph>
        These Terms of Service ("Terms") constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Elysium Arcanum Inc. ("Company," "we," "us," or "our"), concerning your access to and use of the <a href="http://www.ayurdata.ai">www.ayurdata.ai</a> website as well as any other media form, media channel, mobile website, or mobile application related, linked, or otherwise connected thereto (collectively, the "Site"). By accessing the Site, you agree that you have read, understood, and agreed to be bound by all of these Terms. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
      </Paragraph>
      <Paragraph>
        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms by your continued use of the Site after the date such revised Terms are posted.
      </Paragraph>

      <SubHeader>1. OUR SERVICES</SubHeader>
      <Paragraph>
        The services provided by www.ayurdata.ai are designed to comply with major global regulations, including the General Data Protection Regulation (GDPR) for users in Europe and the Health Insurance Portability and Accountability Act (HIPAA) for users in the United States, among other applicable laws and regulations worldwide. We commit to maintaining the highest standards of compliance to ensure that our services are secure and suitable for users no matter their location.
      </Paragraph>

      <SubHeader>2. INTELLECTUAL PROPERTY RIGHTS</SubHeader>
      <Paragraph>
        We retain ownership or hold licenses for all intellectual property on our Site, including Ayurdata AI and AyurEHR technologies. This includes all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics, as well as the trademarks, service marks, and logos contained therein. You are permitted to use our Site and its content, including Ayurdata AI and AyurEHR, for your personal and non-commercial use only, in accordance with these Terms. Any other use, including reproduction, modification, distribution, transmission, or display of the content, is strictly prohibited without our express written consent.
      </Paragraph>

      <SubHeader>3. USER REPRESENTATIONS</SubHeader>
      <Paragraph>
        By using the Site, you represent and warrant that:
      </Paragraph>
      <List>
        <ListItem>All registration information you submit will be true, accurate, current, and complete;</ListItem>
        <ListItem>You will maintain the accuracy of such information and promptly update such registration information as necessary;</ListItem>
        <ListItem>You have the legal capacity and you agree to comply with these Terms;</ListItem>
        <ListItem>You are not under the age of 18;</ListItem>
        <ListItem>You will not access the Site through automated or non-human means, whether through a bot, script, or otherwise;</ListItem>
        <ListItem>You will not use the Site for any illegal or unauthorized purpose;</ListItem>
        <ListItem>Your use of the Site will not violate any applicable law or regulation.</ListItem>
      </List>

      <SubHeader>4. USER REGISTRATION</SubHeader>
      <Paragraph>
        You may be required to register with the Site to access certain features. During registration, you agree to provide true, accurate, current, and complete information about yourself as prompted by the Site's registration form. You agree to maintain the accuracy of such information and promptly update such registration information as necessary. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable. We also reserve the right to disable any user account at our sole discretion should we suspect any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete.
      </Paragraph>

      <SubHeader>5. PROHIBITED ACTIVITIES</SubHeader>
      <Paragraph>
        You may not access or use the Site for any purpose other than those explicitly outlined by us. Prohibited activities include, but are not limited to, engaging in illegal activities, infringing upon the intellectual property rights of others, and participating in fraudulent behaviors. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
      </Paragraph>

      <SubHeader>6. USER GENERATED CONTRIBUTIONS</SubHeader>
      <Paragraph>
        The Site does not offer users the opportunity to submit or post content. We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions will not be viewable by other users of the Site or through third-party websites.
      </Paragraph>

      <SubHeader>7. CONTRIBUTION LICENSE</SubHeader>
      <Paragraph>
        By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such feedback for any purpose without compensation to you.
      </Paragraph>

      <SubHeader>8. SERVICES MANAGEMENT</SubHeader>
      <Paragraph>
        We reserve the right, but not the obligation, to:
      </Paragraph>
      <List>
        <ListItem>Monitor the Site for violations of these Terms;</ListItem>
        <ListItem>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms;</ListItem>
        <ListItem>In our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</ListItem>
        <ListItem>Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</ListItem>
      </List>

      <SubHeader>9. TERM AND TERMINATION</SubHeader>
      <Paragraph>
        These Terms shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON.
      </Paragraph>

      <SubHeader>10. MODIFICATIONS AND INTERRUPTIONS</SubHeader>
      <Paragraph>
        We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.
      </Paragraph>

      <SubHeader>11. GOVERNING LAW</SubHeader>
      <Paragraph>
        These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law principles. You and the Company irrevocably consent to the exclusive jurisdiction and venue of the federal and state courts located in Kent County, Dover, Delaware for any disputes arising out of or related to these Terms.
      </Paragraph>

      <SubHeader>12. DISPUTE RESOLUTION</SubHeader>
      <Paragraph>
      In the interest of resolving disputes between you and the Company in the most expedient and cost-effective manner, you and the Company agree to first attempt to negotiate any dispute informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one party to the other. If the dispute cannot be resolved through direct discussions, it shall then be resolved by binding arbitration administered by the American Arbitration Association under its Commercial Mediation Procedures and if unresolved, through its Commercial Arbitration Rules. The arbitration will take place in Dover, Delaware, and shall
      </Paragraph>

      <SubHeader>13. CORRECTIONS</SubHeader>
  <Paragraph>
    There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
  </Paragraph>

  <SubHeader>14. DISCLAIMER</SubHeader>
  <Paragraph>
    THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
  </Paragraph>

  <SubHeader>15. LIMITATIONS OF LIABILITY</SubHeader>
  <Paragraph>
    Except in jurisdictions where such provisions are restricted, in no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the Site, even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to us during the six (6) month period prior to any cause of action arising.
  </Paragraph>

  <SubHeader>16. INDEMNIFICATION</SubHeader>
  <Paragraph>
    You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of your use of the Site.
  </Paragraph>
  <SubHeader>17. USER DATA</SubHeader>
  <Paragraph>
    We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of Ayurdata AI and AyurEHR. This includes safeguarding the personal and health information of users stored within AyurEHR in accordance with applicable data protection laws. Our commitment to the security of user data is outlined in our Privacy Policy, which complements these Terms.
  </Paragraph>

  <SubHeader>18. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</SubHeader>
  <Paragraph>
    Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.
  </Paragraph>

  <SubHeader>19. CULTURAL CONSIDERATIONS</SubHeader>
  <Paragraph>
    We recognize the importance of cultural diversity and are committed to respecting cultural and legal differences across the globe. Our terms and services are carefully designed to be inclusive and adaptable to meet the cultural norms and legal standards of various regions, ensuring a respectful and compliant user experience. These Terms of Service are provided in English. Translations into other languages are available for your convenience. While we strive to ensure that all translations are accurate, in the case of any discrepancies, the English version will prevail. Please contact us using the information in Section 23 if you require a translated version.
  </Paragraph>

  <SubHeader>20. Ayurdata AI Usage Rights and Responsibilities</SubHeader>
  <Paragraph>
    Use of Ayurdata AI, our advanced artificial intelligence technology, is governed by these Terms. Ayurdata AI provides users with enhanced data processing capabilities, including but not limited to data analysis, predictive modeling, and decision support functionalities. You are granted a non-exclusive, non-transferable, revocable license to access and use Ayurdata AI strictly in accordance with these Terms. You agree not to use Ayurdata AI for any purpose that is unlawful or prohibited by these Terms, and to respect all intellectual property rights and confidentiality agreements. You acknowledge that Ayurdata AI is provided "as is" and subject to our disclaimer of warranties and limitations of liability as stated herein.
  </Paragraph>

  <SubHeader>21. Legal Use and Protection for AyurEHR</SubHeader>
  <Paragraph>
    AyurEHR, our electronic health record system, is designed to store and manage the medical and personal health information of patients securely. You must use AyurEHR in compliance with all applicable privacy and data protection laws, including GDPR and HIPAA, ensuring the confidentiality and integrity of patient data. Unauthorized access, use, alteration, or destruction of any information contained in AyurEHR will result in immediate termination of your access and may lead to legal action. We employ industry-standard security measures to protect your data but cannot guarantee that such measures will always prevent unauthorized access or loss.
  </Paragraph>

  <SubHeader>22. MISCELLANEOUS</SubHeader>
  <Paragraph>
    These Terms and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us.
  </Paragraph>

  <SubHeader>23. CONTACT US</SubHeader>
  <Paragraph>
    In order to resolve a complaint regarding the Site or to receive further information regarding the use of the Site, please contact us at:
  </Paragraph>
  <Paragraph>
    Elysium Arcanum Inc.<br/>
    2747 Haste St APT 12<br/>
    Berkeley, California, United States of America, 94704<br/>
    Email: tos@ayurdata.ai<br/>
    Phone: (+1) 341-207-2186
  </Paragraph>
</Container>
  );
}

export default TermsPage;